import { useEffect } from "react";
import GalleryPicture from "../../pictures/gallery.png";

export default function Title() {
  useEffect(() => {
    const callback = function (entries) {
      entries.forEach((entry) => {
        console.log(entry);

        if (entry.isIntersecting) {
          entry.target.classList.add("animation");
        } else {
          entry.target.classList.remove("animation");
        }
      });
    };
    const imgCallback = function (entries) {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add("img-animation");
        } else {
          entry.target.classList.remove("img-animation");
        }
      });
    };

    const observer = new IntersectionObserver(callback);

    const targets = document.querySelectorAll(".js-show-on-scroll");
    targets.forEach(function (target) {
      target.classList.add("opacity-0");
      observer.observe(target);
    });

    const newObserver = new IntersectionObserver(imgCallback);
    const imgTarget = document.querySelectorAll(".js-show-on-scroll-2")[0];
    newObserver.observe(imgTarget);
  });

  return (
    <div className="flex lg:flex-row flex-col overflow-clip items-center">
      <div className="lg:w-1/3 lg:py-48 pt-16 flex justify-left lg:mx-24 mx-16 js-show-on-scroll ">
        <div>
          <div className="lg:text-6xl text-3xl my-10 font-bold text-gray-700">
            What is worth creating and why?
          </div>
          <div className="text-md my-5 text-left font-light max-w-md">
            Founders and researchers working together to create transformative
            beneficial technological and scientific progress.
          </div>
        </div>
      </div>
      <div className="lg:w-2/3 lg:pl-10 lg:pt-16 pt-12 px-8 ">
        <img
          src={GalleryPicture}
          style={{ opacity: 0.7 }}
          className="js-show-on-scroll-2"
        ></img>
      </div>
    </div>
  );
}
