export default function Description() {
  const callback = function (entries) {
    entries.forEach((entry) => {
      console.log(entry);

      if (entry.isIntersecting) {
        entry.target.classList.add("animation");
      } else {
        entry.target.classList.remove("animation");
      }
    });
  };

  const observer = new IntersectionObserver(callback);

  const targets = document.querySelectorAll(".js-show-on-scroll");
  targets.forEach(function (target) {
    target.classList.add("opacity-0");
    observer.observe(target);
  });

  return (
    <div className="mx-18 lg:mt-20 mt-10 flex justify-center">
      <div className="w-2/3">
        <div className="font-bold text-xl text-gray-700 js-show-on-scroll">
          House Values
        </div>
        <div className="py-5 font-light">
          <div className="js-show-on-scroll">
            We are NeoGenesis, a collective of founders and scientists building
            a higher tomorrow. We intend to create incredible insights, tools,
            and institutions which fundamentally alter the trajectory of
            humanity for the better. To that end, we have the tenets of the
            house that we promise to adhere to. These are: 
          </div>
          <div class="">
            <div className="py-2 mt-10">
              <div className="js-show-on-scroll">
                <div className="font-bold">
                  The Creation and Expression of New Values
                </div>
                Express a life beyond known values & identities.
              </div>
            </div>
            <div className="js-show-on-scroll py-2">
              <div className="font-bold">Creation & Creativity</div>
              Creation as the path to improving humanity's trajectory.
            </div>
            <div className="js-show-on-scroll py-2">
              <div className="font-bold">Action Bias</div>Default to favor
              decisive action.
            </div>
            <div className="js-show-on-scroll py-2">
              <div className="font-bold">Learning & Knowledge</div>Understanding
              is fundamental to everything we value.
            </div>
            <div className="js-show-on-scroll py-2">
              <div className="font-bold">Community​​</div>Invested, lasting
              relationships that grow the people around us.​​
            </div>
            <div className="js-show-on-scroll py-2">
              <div className="font-bold">Conceptual Depth</div>The way we
              represent our reality determines our conception of the way our
              world is ordered.
            </div>
            <div className="js-show-on-scroll py-2">
              <div className="font-bold">Technical Depth</div>Through which the
              nature of reality is revealed and engaged.
            </div>
            <div className="js-show-on-scroll py-2 mt-10">
              It can be as important to define what you are for as what you are
              against. We reject:
              <div className="py-2">
                <div className="py-2">Stasis, Sclerosis & Complacency</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
