export default function Form() {
  return (
    <div className="p-10">
      <iframe
        class="airtable-embed"
        src="https://airtable.com/embed/shrQaTr0QvGfajyne?backgroundColor=purple"
        frameborder="0"
        onmousewheel=""
        width="100%"
        height="533"
      ></iframe>
    </div>
  );
}
