import "./App.css";
import Title from "./components/HomePage/Title";
import Description from "./components/HomePage/Description";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import PicCarousel from "./components/Carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Divider } from "@mui/material";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import CommunityTitle from "./components/Community/Title";
import CommunityForm from "./components/Community/Form";
import HostTitle from "./components/Host/Title";
import HostForm from "./components/Host/Form";
import SponsorTitle from "./components/Sponsor/Title";
import SponsorForm from "./components/Sponsor/Form";
import Content from "./components/HomePage/Content";

function Home() {
  return (
    <>
      <Navbar />
      <div>
        <Title />
        <div className="px-48 mt-10">
          <Divider variant="middle" className="" />
        </div>
        <Description />
        <div className="px-48 my-10">
          <Divider variant="middle" />
        </div>
        <Content />
        <div className="px-48 mb-10">
          <Divider variant="middle" />
        </div>
        <Footer />
      </div>
    </>
  );
}

function Community() {
  return (
    <>
      <Navbar />
      <div className="py-20"></div>
      <CommunityForm />
    </>
  );
}

function Host() {
  return (
    <>
      <Navbar />
      <div className="py-20"></div>
      <HostForm />
    </>
  );
}

function Sponsor() {
  return (
    <>
      <Navbar />
      <div className="py-20"></div>
      <SponsorForm />
    </>
  );
}

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/community" element={<Community />} />
        <Route path="/host" element={<Host />} />
        <Route path="/sponsor" element={<Sponsor />} />
      </Routes>
    </Router>
  );
}

export default App;
