import React from "react";
import { GiHamburgerMenu } from "react-icons/gi";
import { Link } from "react-router-dom";
import Icon from "../pictures/logo.png";

export default function Navbar() {
  const [navbarOpen, setNavbarOpen] = React.useState(false);

  return (
    <>
      <nav className="z-10 flex flex-wrap items-center justify-between px-2 py-3 fixed bg-white w-full">
        <div className="container px-4 mx-auto flex flex-wrap items-center justify-between">
          <div className="w-full relative flex justify-between lg:w-auto lg:static lg:block lg:justify-start">
            <Link
              to="/"
              className="text-sm font-bold leading-relaxed inline-block mr-4 py-2 whitespace-nowrap uppercase text-black"
            >
              <div className="flex flex-row h-12 align-center object-contain items-center">
                <div className="flex items-center align-middle h-8">
                  <img src={Icon} className="object-contain h-8"></img>
                </div>
                NeoGenesis
              </div>
            </Link>
            <button
              className="text-black cursor-pointer text-xl leading-none px-3 py-1 border border-solid border-transparent rounded bg-transparent block lg:hidden outline-none focus:outline-none"
              type="button"
              onClick={() => setNavbarOpen(!navbarOpen)}
            >
              <GiHamburgerMenu />
            </button>
          </div>
          <div
            className={
              "lg:flex flex-grow items-center" +
              (navbarOpen ? " flex" : " hidden")
            }
            id="example-navbar-danger"
          >
            <ul className="flex flex-col lg:flex-row list-none lg:ml-auto">
              <li className="nav-item">
                <a href="/community">
                  <a className="ml-2 px-3 py-2 flex items-center text-xs uppercase font-bold leading-snug hover:opacity-75">
                    Join our Community
                  </a>
                </a>
              </li>
              <li className="nav-item">
                <a href="/host">
                  <a className="ml-2 px-3 py-2 flex items-center text-xs uppercase font-bold leading-snug hover:opacity-75">
                    Host an Event With Us
                  </a>
                </a>
              </li>
              <li className="nav-item">
                <a href="/sponsor">
                  <a className="ml-2 px-3 py-2 flex items-center text-xs uppercase font-bold leading-snug hover:opacity-75">
                    Sponsor us
                  </a>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
}
