import HousePicture from "../../pictures/filtered/neogenesis.png";
import DemoPicture from "../../pictures/filtered/demo.png";
import FlyingCarPicture from "../../pictures/filtered/flyingcar.png";
import { AiOutlineArrowRight } from "react-icons/ai";

export default function Content() {
  const callback = function (entries) {
    entries.forEach((entry) => {
      console.log(entry);

      if (entry.isIntersecting) {
        entry.target.classList.add("animation");
      } else {
        entry.target.classList.remove("animation");
      }
    });
  };

  const observer = new IntersectionObserver(callback);

  const targets = document.querySelectorAll(".js-show-on-scroll");
  targets.forEach(function (target) {
    target.classList.add("opacity-0");
    observer.observe(target);
  });

  return (
    <div className="flex flex-col p-10 js-show-on-scroll">
      <div className="mb-10 flex flex-col items-center">
        <div className="max-w-xl hover:max-w-2xl transition-all duration-500">
          <img src={HousePicture}></img>
        </div>
        <a href="/community">
          <button className="m-5 p-5 btn relative inline-flex items-center justify-start overflow-hidden transition-all bg-white rounded hover:bg-white group font-light text-xl">
            <span className="w-0 h-0 rounded bg-gray-700 absolute top-0 left-0 ease-out duration-500 transition-all group-hover:w-full group-hover:h-full -z-1"></span>
            <span className="w-fit text-black transition-colors duration-300 ease-in-out group-hover:text-white z-10 flex flex-row items-center">
              Join our community
              <AiOutlineArrowRight className="ml-2"></AiOutlineArrowRight>
            </span>
          </button>
        </a>
      </div>
      <div className="mb-10 flex flex-col  items-center">
        <div className="max-w-xl hover:max-w-2xl transition-all duration-500">
          <img src={DemoPicture}></img>
        </div>
        <a href="/host">
          <button className="m-5 p-5 btn relative inline-flex items-center justify-start overflow-hidden transition-all bg-white rounded hover:bg-white group font-light text-xl">
            <span className="w-0 h-0 rounded bg-gray-700 absolute top-0 left-0 ease-out duration-500 transition-all group-hover:w-full group-hover:h-full -z-1"></span>
            <span className="w-fit text-black transition-colors duration-300 ease-in-out group-hover:text-white z-10 flex flex-row items-center">
              Host an event with us
              <AiOutlineArrowRight className="ml-2"></AiOutlineArrowRight>
            </span>
          </button>
        </a>
      </div>
      <div className="mb-10 flex flex-col overflow-wrap items-center">
        <div className="max-w-xl hover:max-w-2xl transition-all duration-500">
          <img src={FlyingCarPicture}></img>
        </div>
        <a href="/sponsor">
          <button className="m-5 p-5 btn relative inline-flex items-center justify-start overflow-hidden transition-all bg-white rounded hover:bg-white group font-light text-xl">
            <span className="w-0 h-0 rounded bg-gray-700 absolute top-0 left-0 ease-out duration-500 transition-all group-hover:w-full group-hover:h-full -z-1"></span>
            <span className="w-fit text-black transition-colors duration-300 ease-in-out group-hover:text-white z-10 flex flex-row items-center">
              Sponsor us
              <AiOutlineArrowRight className="ml-2"></AiOutlineArrowRight>
            </span>
          </button>
        </a>
      </div>
    </div>
  );
}
